<template>
	<Modal :footer-hide="true" v-model="showDiaryManage2" width="1334">
		<p slot="header" style="font-size: 18px;font-weight: bold;color: #333333;" class="modalHeader">每日成交</p>
		<DownloadBtn :id="10"
			:style="{position: 'relative',left: 0,'margin-bottom': '20px','background-color': 'rgba(241, 110, 113, 1)',}"
			@exportData="exportData">
		</DownloadBtn>
		<Table :columns="tableHeads" :data="tableData" border tooltip-theme="light" height="540" class="table"
			:loading="loading"></Table>
		<Page :current="formItem.current" :total="formItem.total" show-elevator show-sizer
			@on-page-size-change="pageSizeChange" @on-change="pageChange" />
	</Modal>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import DownloadBtn from '@/components/DownloadBtn'
	import {
		downloadData,
		formatJson
	} from "@/utils/index";
	import {
		get_bargain_data,
		dailyTransaction
	} from '@/api/city';
	export default {
		props: {
			item: Object,
			nowChooseItem: Object,
			statPropertyTypeList: Array
		},
		components: {
			DownloadBtn
		},
		data() {
			return {
				loading: false,
				tableData: [],
				formItem: {
					size: 10,
					current: 1,
					total: 0,
				}
			}
		},
		mounted() {},
		computed: {
			...mapState({
				cityData(state) {
					return state.city.option;
				},
			}),
			diaryId2: {
				get() {
					return this.$store.state.modal.diaryId2;
				},
				set(newValue) {
					this.$store.state.modal.diaryId2 = newValue;
				}
			},
			showDiaryManage2: {
				get() {
					return this.$store.state.modal.showDiaryManage2;
				},
				set(newValue) {
					this.$store.state.modal.showDiaryManage2 = newValue;
				}
			},
			tableHeads() {
				let arr = [{
						title: '成交日期',
						key: 'cjri',
						align: 'center',
						width: 150,

						tooltip: true,
						'tooltip-theme': 'light',
					},
					{
						title: '小区名称',
						key: 'lpmc',
						align: 'center',
						width: 200,

						tooltip: true,
						'tooltip-theme': 'light',
					},
					{
						title: '楼盘名称',
						key: 'ldmc',
						align: 'center',
						width: 150,

						tooltip: true,
						'tooltip-theme': 'light',
					},
					{
						title: '房号',
						key: 'xh',
						align: 'center',
						width: 100,

						tooltip: true,
						'tooltip-theme': 'light',
					},
					{
						title: '物业类型',
						key: 'wylx',
						align: 'center',
						width: 100,

						tooltip: true,
						'tooltip-theme': 'light',
					},
					{
						title: '详细类型',
						key: 'xxlx',
						align: 'center',
						width: 100,

						tooltip: true,
						'tooltip-theme': 'light',
					},
					{
						title: '建筑面积',
						key: 'jzmj',
						align: 'center',
						width: 100,

						tooltip: true,
						'tooltip-theme': 'light',
						renderHeader: (h, index) => {
							return h("div", [h("div", "建筑面积"), h("div", "(㎡)")]);
						}
					},
					{
						title: '套内面积',
						key: 'tnmj',
						align: 'center',
						width: 100,

						tooltip: true,
						'tooltip-theme': 'light',
						renderHeader: (h, index) => {
							return h("div", [h("div", "套内面积"), h("div", "(㎡)")]);
						}
					},
					{
						title: '公摊面积',
						key: 'gtmj',
						align: 'center',
						width: 100,

						tooltip: true,
						'tooltip-theme': 'light',
						renderHeader: (h, index) => {
							return h("div", [h("div", "公摊面积"), h("div", "(㎡)")]);
						}
					},
					{
						title: '成交均价',
						key: 'cjjj',
						align: 'center',
						width: 100,

						tooltip: true,
						'tooltip-theme': 'light',
						renderHeader: (h, index) => {
							return h("div", [h("div", "成交均价"), h("div", "(元/㎡)")]);
						}
					},
					{
						title: '成交金额',
						key: 'cjje',
						align: 'center',
						width: 100,
						tooltip: true,
						'tooltip-theme': 'light',
						renderHeader: (h, index) => {
							return h("div", [h("div", "成交金额"), h("div", "(万元)")]);
						}
					}
				];

				return arr;
			}
		},
		watch: {
			showDiaryManage2(val) {
				if (val) {
					this.formItem.current = 1;
					this.getData();
				}
			}
		},
		methods: {
			async getData() {
				let data = await this.getList(this.formItem.current, this.formItem.size)
				this.formItem.total = data.total;
				this.tableData = data.records;
				this.formItem.current = data.current
			},
			getList(current, size) {
				return new Promise((res, rej) => {
					let item = this.item;
					let nowChooseItem = this.nowChooseItem
					//获取对应的物业类型的code的数组（只有一个）
					let codeArray = this.statPropertyTypeList.filter(item => {
						return item.name == nowChooseItem.propertyType
					})
					let arr = [];
					this.loading = true;
					dailyTransaction({
							cityTimes: item.optionTime && (item.optionTime.split("至")),
							size: size,
							current: current,
							housesId: this.diaryId2,
							housesType: [`${codeArray[0].code}`],
						}).then(data => {
							res(data)
						}).catch(err => {
							rej(err)
						})
						.finally(() => {
							this.loading = false;
						})
				})

			},
			pageSizeChange(size) {
				this.formItem.current = 1;
				this.formItem.size = size;
				this.getData();
			},
			pageChange(size) {
				this.formItem.current = size;
				let target = document.querySelector(".ivu-modal .ivu-table-body");
				target && (target.scrollTop = 0);
				this.getData();
			},
			async exportData() {
				try {
					let data = await this.getList(1, 999)
					let filterVal = []
					let tHeader = []
					this.tableHeads.forEach((item) => {
						tHeader.push(item.title)
						filterVal.push(item.key)
					})
					const resData = formatJson(filterVal, data.records);
					downloadData(tHeader, resData, "每日成交")															
				} catch (err) {
					this.$toast({
						text: err
					})
				}


			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "~@/style/common.scss";

	/deep/ .ivu-page {
		position: relative;
		margin: 15px 0;
		text-align: right;
	}
</style>

<template>
  <div class="page">
    <div class="top-line">
      <Select v-model="cityId" clearable filterable placeholder="选择城市" @on-change="choosePageCity"
              style="width: 120px">
        <Option v-for="item in cityList" :value="item.code" :key="item.code">{{ item.name }}</Option>
      </Select>
      <div class="line-tabs">
        <div class="tab-item" v-for="(item, index) in topTabs" :key="index" @click="changeTopTab(index)"
             :class="{active: index == topTabIndex}">{{ item.name }}
        </div>
      </div>
    </div>

    <div class="tab">
      <div class="tab-item" v-for="(item, index) in tabList" :key="index" @click="changeTab(index)"
           :class="{ active: index == tabIndex }">{{ item.name }}
      </div>
    </div>
    <!-- 显示为楼盘统计 -->
    <div class="option-box" v-if="topTabIndex == 0">
      <Form :model="nowFormItem">

        <FormItem label="物业类型">
          <RadioGroup v-model="nowFormItem.statPropertyType[0]">
            <Radio :label="item.code" v-for="item in statPropertyTypeList" :key="item.code">{{ item.name }}
            </Radio>
          </RadioGroup>
        </FormItem>

        <FormItem label="区域">
          <Checkbox :value="checkAreaAll" style="display: inline"
                    @click.prevent.native="handleCheckAll(checkAreaAll, areaList, 'areaId')">全市
          </Checkbox>
          <CheckboxGroup v-model="nowFormItem.areaId" style="display: inline">
            <Checkbox :label="item.code" v-for="item in areaList" :key="item.code">{{ item.name }}
            </Checkbox>
          </CheckboxGroup>
        </FormItem>

        <FormItem label="周期">
          <RadioGroup v-model="nowFormItem.time">
            <Select v-model="nowFormItem.timePeriod" @on-change="changeTimePeriod" style="width: 70px">
              <Option v-for="item in cityData.statTimeNoDay" :value="item.id" :key="item.id">
                {{ item.name }}
              </Option>
            </Select>
            <DatePicker :editable='false' :type="timeType || 'year'" :disabled="!nowFormItem.timePeriod"
                        @on-change="changeTime(1)" v-model="nowFormItem.timeStart" placeholder="开始时间"
                        style="width: 96px; margin-left: 11px"></DatePicker>
            <Select v-model="nowFormItem.quarterStart" v-show="nowFormItem.timePeriod == 2"
                    @on-change="checkTime" placeholder="季度" style="width: 70px">
              <Option v-for="item in quarterList" :value="item" :key="item">{{ item }}</Option>
            </Select>-
            <DatePicker :editable='false' :type="timeType || 'year'" :disabled="!nowFormItem.timePeriod"
                        v-model="nowFormItem.timeEnd" @on-change="changeTime(2)" placeholder="结束时间"
                        style="width: 96px"></DatePicker>
            <Select v-model="nowFormItem.quarterEnd" v-show="nowFormItem.timePeriod == 2"
                    @on-change="checkTime" placeholder="季度" style="width: 70px">
              <Option v-for="item in quarterList" :value="item" :key="item">{{ item }}</Option>
            </Select>
          </RadioGroup>
        </FormItem>

        <FormItem label="楼盘">
          <Select v-model="nowFormItem.houses" filterable multiple style="width: 300px; margin-right: 10px">
            <Option v-for="item in houses" :value="item.code" :key="item.code">{{ item.name }}</Option>
          </Select>
          <Button @click="nowFormItem.houses = []">清空</Button>
        </FormItem>
      </Form>
    </div>
    <!-- 显示为行政区统计 -->

    <div class="option-box" v-if="topTabIndex == 1">
      <Form :model="nowFormItem">
        <FormItem label="周期">
          <RadioGroup v-model="nowFormItem.time">
            <Select v-model="nowFormItem.timePeriod" @on-change="changeTimePeriod" style="width: 70px">
              <Option v-for="item in cityData.statTimeNoDay" :value="item.id" :key="item.id">
                {{ item.name }}
              </Option>
            </Select>
            <DatePicker :editable='false' :type="timeType || 'year'" :disabled="!nowFormItem.timePeriod"
                        @on-change="changeTime(1)" v-model="nowFormItem.timeStart" placeholder="开始时间"
                        style="width: 96px; margin-left: 11px"></DatePicker>
            <Select v-model="nowFormItem.quarterStart" v-show="nowFormItem.timePeriod == 2"
                    @on-change="checkTime" placeholder="季度" style="width: 70px">
              <Option v-for="item in quarterList" :value="item" :key="item">{{ item }}</Option>
            </Select>-
            <DatePicker :editable='false' :type="timeType || 'year'" :disabled="!nowFormItem.timePeriod"
                        v-model="nowFormItem.timeEnd" @on-change="changeTime(2)" placeholder="结束时间"
                        style="width: 96px"></DatePicker>
            <Select v-model="nowFormItem.quarterEnd" v-show="nowFormItem.timePeriod == 2"
                    @on-change="checkTime" placeholder="季度" style="width: 70px">
              <Option v-for="item in quarterList" :value="item" :key="item">{{ item }}</Option>
            </Select>
          </RadioGroup>
        </FormItem>
        <FormItem label="区域">

          <Checkbox :value="checkAreaAll" style="display: inline"
                    @click.prevent.native="handleCheckAll(checkAreaAll, areaList, 'areaId')">全市
          </Checkbox>
          <CheckboxGroup v-model="nowFormItem.areaId" style="display: inline">
            <Checkbox :label="item.code" v-for="item in areaList" :key="item.code">{{ item.name }}
            </Checkbox>
          </CheckboxGroup>

        </FormItem>


        <FormItem label="物业类型">
          <CheckboxGroup v-model="nowFormItem.statPropertyType">
            <template v-for="item in statPropertyTypeList">
              <Checkbox v-if="item.code == '1.1'"
                        :disabled="nowFormItem.statPropertyType.length ? nowFormItem.statPropertyType.indexOf('1.1') == -1:false"
                        :label="item.code" :key="item.code">{{ item.name }}
              </Checkbox>
              <Checkbox v-else :disabled="nowFormItem.statPropertyType.indexOf('1.1') > -1"
                        :label="item.code" :key="item.code">{{ item.name }}
              </Checkbox>
            </template>
          </CheckboxGroup>
        </FormItem>


        <FormItem label="细分条件">
          <div class="option-line">
            <div class="item-title" @click="chooseOption(1)"
                 :class="{ active: nowFormItem.chooseOption == 1 }">
              户型结构
            </div>
            <div class="item-title" @click="chooseOption(2)"
                 :class="{ active: nowFormItem.chooseOption == 2 }">
              面积区间
            </div>
            <div class="item-title" @click="chooseOption(3)"
                 :class="{ active: nowFormItem.chooseOption == 3 }">
              价格区间
            </div>
          </div>
          <div class="des-line">
            <template v-if="nowFormItem.chooseOption == 1">
              <CheckboxGroup v-model="nowFormItem.apartment">
                <Checkbox :label="item.code" :disabled="nowFormItem.chooseOption != 1"
                          v-for="item in apartmentList" :key="item.code">{{ item.name }}
                </Checkbox>
              </CheckboxGroup>
            </template>
            <template v-if="nowFormItem.chooseOption == 2">
              <CheckboxGroup v-model="nowFormItem.statAcreage">
                <Checkbox :label="item.code" :disabled="nowFormItem.chooseOption != 2"
                          v-for="item in statAcreageList" :key="item.code">{{ item.name }}
                </Checkbox>
              </CheckboxGroup>
            </template>
            <template v-if="nowFormItem.chooseOption == 3">
              <CheckboxGroup v-model="nowFormItem.statPrice">
                <Checkbox :label="item.code" :disabled="nowFormItem.chooseOption != 3"
                          v-for="item in statPriceList" :key="item.code">{{ item.name }}
                </Checkbox>
              </CheckboxGroup>
            </template>
          </div>
        </FormItem>
      </Form>
    </div>
    <!-- 显示为开发商统计统计 -->

    <div class="option-box" v-if="topTabIndex == 2">
      <Form :model="nowFormItem">
        <FormItem label="物业类型">
          <RadioGroup v-model="nowFormItem.statPropertyType[0]">
            <Radio :label="item.code" v-for="item in statPropertyTypeList" :key="item.code">{{ item.name }}
            </Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="周期">
          <RadioGroup v-model="nowFormItem.time">
            <Select v-model="nowFormItem.timePeriod" @on-change="changeTimePeriod" style="width: 70px">
              <Option v-for="item in cityData.statTimeNoDay" :value="item.id" :key="item.id">
                {{ item.name }}
              </Option>
            </Select>
            <DatePicker :editable='false' :type="timeType || 'year'" :disabled="!nowFormItem.timePeriod"
                        @on-change="changeTime(1)" v-model="nowFormItem.timeStart" placeholder="开始时间"
                        @on-clear="hideAlerMsg"
                        style="width: 96px; margin-left: 11px"></DatePicker>
            <Select v-model="nowFormItem.quarterStart" v-show="nowFormItem.timePeriod == 2"
                    @on-change="checkTime" placeholder="季度" style="width: 70px">
              <Option v-for="item in quarterList" :value="item" :key="item">{{ item }}</Option>
            </Select>-
            <DatePicker :editable='false' :type="timeType || 'year'" :disabled="!nowFormItem.timePeriod"
                        v-model="nowFormItem.timeEnd" @on-change="changeTime(2)" placeholder="结束时间"
                        @on-clear="hideAlerMsg"
                        style="width: 96px"></DatePicker>
            <Select v-model="nowFormItem.quarterEnd" v-show="nowFormItem.timePeriod == 2"
                    @on-change="checkTime" placeholder="季度" style="width: 70px">
              <Option v-for="item in quarterList" :value="item" :key="item">{{ item }}</Option>
            </Select>
          </RadioGroup>
        </FormItem>
        <FormItem label="选择企业">
          <div class="areaTransfer company">
            <div class="leftTransfer">
              <div class="leftTransferSearch">
                <Input v-model="nowFormItem.searchCity" placeholder="输入关键字搜索" @on-change="searchCom"/>
                <span class="btn-all" @click="mutliSelectAll">全选</span>
              </div>

              <div class="leftTransferBottom">
                <div class="leftRadio">
                  <RadioGroup v-model="nowFormItem.multiCityRadio" @on-change="getMultiCom">
                    <Radio v-for="(item, index) in enterprise" :key="index" :disabled="index == 0"
                           :label="index">{{ item.name }}
                    </Radio>
                  </RadioGroup>
                </div>
                <div class="rightBox">
                  <template v-if="nowFormItem.filterCityList.length">
                    <div class="item" v-for="(item, index) in nowFormItem.filterCityList"
                         @click="chooseCity(item)" :key="index">
                      {{ item.name }}
                    </div>
                  </template>
                  <template v-else>
                    <div class="item" v-for="(item, index) in nowFormItem.multiCityList"
                         @click="chooseCity(item)" :key="index">
                      {{ item.name }}
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <Icon type="md-arrow-round-forward" class="centerBtn"/>

            <div class="rightTransfer">
              <div class="topBtn">
                已选
                <span @click="clearChooseCity">清空</span>
              </div>

              <div class="itemBox">
                <Tooltip max-width="300" theme="light" :content="item.name"
                         v-for="(item, index) in nowFormItem.chooseCityList" :key="index">
                  <Tag closable class="item" color="#00B6FF" @on-close="delCity(item, index)">
                    {{ item.name }}
                  </Tag>
                </Tooltip>
              </div>
            </div>
          </div>
        </FormItem>
      </Form>
    </div>

    <div class="btn-box">
      <div class="btn" @click="saveTemplate">保存模板</div>
      <div class="btn check searchBtnLog" data-id="1" @click="search">查询</div>
      <div class="btn" @click="manageModalSwitch">我的模板</div>
    </div>

    <div class="choose-box">
      <TagGroup :tagList="selectList2" @close="delSingle">
        <Tag type="border" v-if="nowFormItem.optionTime" size="large" @on-close="delTime">周期:<span
            class="tabContent">{{ nowFormItem.optionTime }}</span></Tag>

        <template v-if="topTabIndex == 2 &&nowFormItem.chooseCityList &&nowFormItem.chooseCityList.length">
          <Tag type="border" size="large" @on-close="delSingle({key: 'chooseCityList',type: 'list',})">企业:
            <span class="tabContent">
							<template>{{ showChoseCompany }}</template>
						</span>
          </Tag>
        </template>
      </TagGroup>
    </div>
    <div v-if="showAlertMsg" style='margin-bottom: 20px'>
      <Tooltip style='margin-left: 0px;' :content="alertMsg" disabled>
        <span class="askInfo">!</span>
      </Tooltip>
      <span style="margin-left: 10px;color: #D9001B">{{ alertMsg }}</span>
    </div>
    <div class="table-box">
      <div class="top">
        <DownloadBtn :id="controlId" :type=1 @exportData="exportData"
                     :style="{'background-color': 'rgba(241, 110, 113, 1)'}"></DownloadBtn>
        <!-- 显示为表格 -->
        <div class="action-box" v-if="theadType == 1">
          <!-- 分类||合并 -->
          <Select v-if="topTabIndex == 1" v-model="optionIndex" style="width: 120px; marginright: 10px"
                  size="small" placeholder="">
            <Option v-for="item in detailOpt" :value="item.value" :key="item.value">{{ item.label }}
            </Option>
          </Select>
          <div class="select-box">
            <!-- 筛选指标 -->
            <Select class="select-index" v-if="theadType == 1" v-model="nowFormItem.tableHead"
                    placeholder="" style="width: 200px" size="small" multiple>
              <template v-for="item in nowOptionIndex">
                <Option v-show="filterOptionList.indexOf(item.key) == -1" :value="item.title"
                        :key="item.title">{{ `${item.title}` }}
                </Option>
              </template>
            </Select>
            <div class="place">选择指标</div>
          </div>
        </div>
        <!-- 显示为图表 -->
        <div class="table-opt" v-else>
          <!-- 楼盘统计 -->
          <div class="select-box" v-if="topTabIndex == 0">
            <Select placeholder="" multiple clearable v-model="selectRenderDev"
                    style="width: 120px; marginright: 10px" size="small">
              <template v-if="nowFormItem.houses && nowFormItem.houses.length">
                <Option v-for="item in nowFormItem.houses" :value="item" :key="item">
                  {{ formalName("houses", item) }}
                </Option>
              </template>
              <template v-else>
                <Option v-for="(item, index) in houses" :value="item.code" :key="index">{{ item.name }}
                </Option>
              </template>
            </Select>
            <div class="place">选择楼盘</div>
          </div>
          <!-- 行政区统计 -->
          <Select clearable v-if="topTabIndex == 1" v-model="selectRenderCity"
                  style="width: 120px; marginright: 10px" size="small" placeholder="">
            <template v-if="nowFormItem.areaId && nowFormItem.areaId.length">
              <Option v-for="item in nowFormItem.areaId" :value="item" :key="item">
                {{ formalName("areaList", item) }}
              </Option>
            </template>
            <template v-else>
              <Option v-for="(item, index) in areaList" :value="item.code" :key="index">{{ item.name }}
              </Option>
            </template>
          </Select>
          <!-- 开发商统计统计 -->
          <div class="select-box" v-if="topTabIndex == 2">
            <Select multiple v-model="selectRenderMulCity" style="width: 120px; marginright: 10px"
                    size="small" placeholder="">
              <template v-if="nowFormItem.areaId && nowFormItem.areaId.length">
                <Option v-for="item in nowFormItem.areaId" :value="item" :key="item">
                  {{ formalName("areaList", item) }}
                </Option>
              </template>
              <template v-else>
                <Option v-for="(item, index) in areaList" :value="item.code" :key="index">
                  {{ item.name }}
                </Option>
              </template>
            </Select>
            <div class="place">区域</div>
          </div>
          <!-- 开发商统计统计 -->
          <Select clearable v-if="topTabIndex == 2" v-model="selectRenderCom"
                  style="width: 120px; marginright: 10px" size="small" placeholder="">
            <template v-for="item in nowFormItem.chooseCityList">
              <Option :value="item.code" :key="item.code">{{ item.name }}</Option>
            </template>
          </Select>
          <Select v-model="selectRenderIndex" style="width: 200px" size="small" placeholder="">
            <template v-for="item in filterTableHeads">
              <Option :value="item.title" :key="item.title">{{ `${item.title}` }}</Option>
            </template>
          </Select>
        </div>

        <div class="icon-box" v-if="showTableStyle">
          <div v-for="item in theadOptList" :key="item.type"
               :class="['icon left', { active: theadType === item.type }]" @click="switchThead(item.type)">
          </div>
        </div>

      </div>

      <Table :columns="tableHeads" v-if="theadType == 1" tooltip-theme="light" :data="nowFormItem.tableData"
             border class="table" max-height="600" :loading="loading"></Table>

      <Page :total="nowFormItem.total" :current="nowFormItem.currentIndex" v-if="theadType == 1" show-elevator @on-page-size-change="pageSizeChange"
            @on-change="pageChange"/>

      <div class="canvas-box" id="canvas" v-if="theadType == 2">
      </div>
    </div>

    <devManage :chooseCityList="nowFormItem.chooseCityList" @add="addList"></devManage>
    <diaryManage :item="nowFormItem" :nowChooseItem="nowItem" :statPropertyTypeList='statPropertyTypeList'>
    </diaryManage>
    <modalManage :saveType="modalSaveId" @chooseTemplate="chooseTemplate"></modalManage>
  </div>
</template>

<script>
// @ is an alias to /src
import {getBanner} from "@/api/home";

const echarts = require("echarts");
import {
  searchCity,
  getEnterprise
} from "@/api/public";
import {
  mapState
} from "vuex";
import formMixin from "@/mixins/form";
import modalManage from "@/components/modal/modalManage";
import diaryManage from "@/components/modal/diaryModal2";
import devManage from "@/components/modal/devManage";
import DownloadBtn from "@/components/DownloadBtn";
import dataReports from "@/mixins/dataReports";
import qs from "qs";


import {
  checkDate
} from "@/utils/index";

import {
  get_new_data,
  get_two_data,
  get_rent_data,
  stat_dis_new,
  stat_dis_two,
  stat_dis_rent,
  get_dev_data,
  houses,
  kfs,
  cityDistrict,
  dimHouseDimCompany,
  dimHouseDimCompanyJt,
  listByCategoryCode,
  cityMap,
  districtMap,
  lpMap,
  developerMap,
  statistics_page,
  dailyTransaction,
  getDataCityStatisticsAlertMessagesConfig
} from "@/api/city";
import {
  stringify
} from "querystring";
import {
  downloadData,
  formatJson,
  localeDateStringtoTimestamp,
} from "@/utils/index";

export default {
  name: "cityStat",
  mixins: [formMixin, dataReports],
  components: {
    devManage,
    diaryManage,
    modalManage,
    DownloadBtn,
  },
  data() {
    return {
      originArr: [],
      status2033: false,
      status20341: 0,
      topTabIndex: 0,
      topTabs: [{
        name: "楼盘统计",
      },
        {
          name: "行政区统计",
        },
        {
          name: "开发商统计",
        },
      ],
      tabIndex: 0,

      // 筛选
      quarterList: ["Q1", "Q2", "Q3", "Q4"],

      kfs: [],
      houses: [],
      areaList: [],
      propertyType: [],
      cityId: '',
      // 页面form表单用到的值
      pageSize: 10,
      formItem: {
        chooseOption: 0,
        searchCity: "",
        timePeriod: "",
        timeStart: "",
        timeEnd: "",
        quarterStart: "",
        quarterEnd: "",
        optionTime: "",
        houses: [],
        apartment: [],
        statPrice: [],
        statAcreage: [],
        statPropertyType: ['1.1'],
        multiCityList: [],
        chooseCityList: [],
        filterCityList: [],
        tableHead: [],
        tableData: [],
        areaId: [],
        currentIndex: 1,
      },
      formItem1: {},
      formItem2: {},
      formItem3: {},
      nowItem: null,

      // 各个选项显示的表格头部
      itemOneHead: [
        // {
        //   title: '时间',
        //   key: 'cityTime',
        //   align: 'center',
        //   minWidth: 120,
        //   single : true,
        // },
        {
          title: "楼盘名称",
          key: "housesName",
          align: "center",
          minWidth: 120,
          tooltip: true,
          single: true,
        },
        {
          title: "行政区",
          key: "districtName",
          align: "center",
          minWidth: 120,
          single: true,
        },
        {
          title: "物业类型",
          key: "propertyType",
          align: "center",
          minWidth: 120,
          single: true,
        },
        {
          title: "成交记录",
          key: "diary",
          align: "center",
          minWidth: 120,
          single: true,
          render: (h, params) => {
            return h(
                "div", {
                  attrs: {
                    class: "btn-diary",
                  },
                  on: {
                    click: () => this.showDiaryModal(params),
                  },
                },
                "每日成交"
            );
          },
        },
        {
          title: "批售套数(套)",
          key: "sellNum",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "批售套数"), h("div", "(套)")]);
          },
        },
        {
          title: "批售面积(㎡)",
          key: "sellArea",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "批售面积"), h("div", "(㎡)")]);
          },
        },
        {
          title: "成交套数(套)",
          key: "bargainNum",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "成交套数"), h("div", "(套)")]);
          },
        },
        {
          title: "成交面积(㎡)",
          key: "bargainArea",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "成交面积"), h("div", "(㎡)")]);
          },
        },
        // {
        // 	title: "成交均价(元/㎡)",
        // 	key: "bargainAvgPrice",
        // 	align: "center",
        // 	minWidth: 120,
        // 	renderHeader: (h, index) => {
        // 		return h("div", [h("div", "成交均价"), h("div", "(元/㎡)")]);
        // 	},
        // },
        // {
        // 	title: "成交金额(万元)",
        // 	key: "bargainMoney",
        // 	align: "center",
        // 	minWidth: 140,

        // 	tooltip: true,

        // 	renderHeader: (h, index) => {
        // 		return h("div", [h("div", "成交金额"), h("div", "(万元)")]);
        // 	},
        // },
        {
          title: "库存面积(㎡)",
          key: "stockArea",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "库存面积"), h("div", "(㎡)")]);
          },
        },
        // {
        // 	title: "供求比",
        // 	key: "supplyDeRatio",
        // 	align: "center",
        // 	minWidth: 120,
        // 	single: true
        // },
        // {
        // 	title: "去化周期(月)",
        // 	key: "sellingCycle",
        // 	align: "center",
        // 	minWidth: 120,
        // 	single: true,
        // 	renderHeader: (h, index) => {
        // 		return h("div", [h("div", "去化周期"), h("div", "(月)")]);
        // 	},
        // },
        // {
        //   title: '同比',
        //   key: 'with',
        //   align: 'center',
        // },
        // {
        //   title: '环比',
        //   key: 'loop',
        //   align: 'center',
        // },
      ],
      itemTwoHead: [{
        title: "时间",
        key: "cityTime",
        align: "center",
        minWidth: 120,
        single: true,
      },
        {
          title: "行政区",
          key: "districtName",
          align: "center",
          minWidth: 120,
          single: true,
        },
        {
          title: "物业类型",
          key: "propertyType",
          align: "center",
          minWidth: 120,
          single: true,
        },
        {
          title: "批售套数(套)",
          key: "sellNum",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "批售套数"), h("div", "(套)")]);
          },
        },
        {
          title: "批售面积(㎡)",
          key: "sellArea",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "批售面积"), h("div", "(㎡)")]);
          },
        },
        {
          title: "成交套数(套)",
          key: "bargainNum",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "成交套数"), h("div", "(套)")]);
          },
        },
        {
          title: "成交面积(㎡)",
          key: "bargainArea",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "成交面积"), h("div", "(㎡)")]);
          },
        },
        // {
        // 	title: "成交均价(元/㎡)",
        // 	key: "bargainAvgPrice",
        // 	align: "center",
        // 	minWidth: 120,
        // 	renderHeader: (h, index) => {
        // 		return h("div", [h("div", "成交均价"), h("div", "(元/㎡)")]);
        // 	},
        // },
        // {
        // 	title: "成交金额(万元)",
        // 	key: "bargainMoney",
        // 	align: "center",
        // 	minWidth: 140,

        // 	tooltip: true,

        // 	renderHeader: (h, index) => {
        // 		return h("div", [h("div", "成交金额"), h("div", "(万元)")]);
        // 	},
        // },
        {
          title: "库存面积(㎡)",
          key: "stockArea",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "库存面积"), h("div", "(㎡)")]);
          },
        },
        // {
        // 	title: "供求比",
        // 	key: "supplyDeRatio",
        // 	align: "center",
        // 	minWidth: 120,
        // 	single: true
        // },
        // {
        // 	title: "去化周期(月)",
        // 	key: "sellingCycle",
        // 	align: "center",
        // 	minWidth: 120,
        // 	single: true,
        // 	renderHeader: (h, index) => {
        // 		return h("div", [h("div", "去化周期"), h("div", "(月)")]);
        // 	},
        // },
        // {
        // 	title: "同比",
        // 	key: "with",
        // 	align: "center",
        // },
        // {
        // 	title: "环比",
        // 	key: "loop",
        // 	align: "center",
        // },
      ],
      itemThreeHead: [
        // {
        //   title: '时间',
        //   key: 'cityTime',
        //   align: 'center',
        //   minWidth: 120,
        //   single : true,
        // },
        {
          title: "开发商名称",
          key: "developersName",
          align: "center",
          minWidth: 120,
          single: true,
          tooltip: true,
        },
        // {
        // 	title: "行政区",
        // 	key: "regionName",
        // 	align: "center",
        // 	minWidth: 120,
        // 	single: true
        // },
        {
          title: "批售套数(套)",
          key: "sellNum",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "批售套数"), h("div", "(套)")]);
          },
        },
        {
          title: "批售面积(㎡)",
          key: "sellArea",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "批售面积"), h("div", "(㎡)")]);
          },
        },
        {
          title: "成交套数(套)",
          key: "bargainNum",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "成交套数"), h("div", "(套)")]);
          },
        },
        {
          title: "成交面积(㎡)",
          key: "bargainArea",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "成交面积"), h("div", "(㎡)")]);
          },
        },
        // {
        // 	title: "成交均价(元/㎡)",
        // 	key: "bargainAvgPrice",
        // 	align: "center",
        // 	minWidth: 120,
        // 	renderHeader: (h, index) => {
        // 		return h("div", [h("div", "成交均价"), h("div", "(元/㎡)")]);
        // 	},
        // },
        // {
        // 	title: "成交金额(万元)",
        // 	key: "bargainMoney",
        // 	align: "center",
        // 	minWidth: 140,

        // 	tooltip: true,

        // 	renderHeader: (h, index) => {
        // 		return h("div", [h("div", "成交金额"), h("div", "(万元)")]);
        // 	},
        // },
        {
          title: "库存面积(㎡)",
          key: "stockArea",
          align: "center",
          minWidth: 120,
          renderHeader: (h, index) => {
            return h("div", [h("div", "库存面积"), h("div", "(㎡)")]);
          },
        },
        // {
        // 	title: "供求比",
        // 	key: "supplyDeRatio",
        // 	align: "center",
        // 	minWidth: 120,
        // 	single: true
        // },
        // {
        // 	title: "去化周期",
        // 	key: "sellingCycle",
        // 	align: "center",
        // 	minWidth: 120,
        // 	single: true
        // }
        // {
        //   title: '同比',
        //   key: 'with',
        //   align: 'center',
        // },
        // {
        //   title: '环比',
        //   key: 'loop',
        //   align: 'center',
        // },
      ],
      itemHeadFilterArr: [],

      loading: false,

      // 渲染图表用
      canvasData: [],
      canvasDevList: [],
      canvasOption: null,
      selectRenderDev: [],
      selectRenderCom: "",
      selectRenderCity: "",
      selectRenderMulCity: [],
      selectRenderType: "",
      selectRenderIndex: "",
      theadOptList: [{
        type: 1,
        background: "rgb(76, 201, 233)",
      },
        {
          type: 2,
          background: "rgb(76, 201, 233)",
        },
      ],
      theadType: 1,
      optionIndex: 1,
      detailOpt: [{
        value: 1,
        label: "分类统计",
      },
        {
          value: 2,
          label: "合并统计",
        },
      ],
      cityList: [],
      statPropertyTypeList: [],
      apartmentList: [],
      statAcreageList: [],
      statPriceList: [],
      cityName: '',
      alertMsg: {
        cityId: "",
        monthStart: "",
        monthEnd: "",
        yearStart: "",
        yearEnd: "",
        message: ""
      },
      showAlertMsg: false
    }
  },
  computed: {
    ...mapState(["enterprise", "landProvinceList"]),
    ...mapState({
      cityData(state) {
        return state.city2.option;
      },
    }),
    // 模板数值
    modalSaveId() {
      let id = 0;
      if (this.topTabIndex == 0) {
        id = 10;
      } else if (this.topTabIndex == 1) {
        id = 11;
      } else {
        id = 12;
      }
      return id;
    },
    showDevManage: {
      get() {
        return this.$store.state.modal.showDevManage;
      },
      set(newValue) {
        this.$store.state.modal.showDevManage = newValue;
      },
    },
    diaryId2: {
      get() {
        return this.$store.state.modal.diaryId2;
      },
      set(newValue) {
        this.$store.state.modal.diaryId2 = newValue;
      },
    },
    showDiaryManage2: {
      get() {
        return this.$store.state.modal.showDiaryManage2;
      },
      set(newValue) {
        this.$store.state.modal.showDiaryManage2 = newValue;
      },
    },
    showModalManage: {
      get() {
        return this.$store.state.modal.showModalManage;
      },
      set(newValue) {
        this.$store.state.modal.showModalManage = newValue;
      },
    },
    saveSearchData: {
      get() {
        return this.$store.state.saveSearchData;
      },
      set(newValue) {
        this.$store.state.saveSearchData = newValue;
      },
    },
    checkAreaAll() {
      return (
          this.nowFormItem.areaId &&
          this.nowFormItem.areaId.length == this.areaList.length
      );
    },

    tabList() {
      let arr = [];
      if (this.topTabIndex == 0) {
        arr = [{
          name: "一手房",
        },];
      } else {
        arr = [{
          name: "一手房",
        },
          // {
          // 	name : "二手房",
          // },
          // {
          // 	name : "租赁",
          // }
        ];
      }
      return arr;
    },
    nowFormItem() {
      if (this.topTabIndex == 0) return this.formItem1;
      if (this.topTabIndex == 1) return this.formItem2;
      if (this.topTabIndex == 2) return this.formItem3;
      return {};
    },
    timeType() {
      let str = "";
      let timePeriod = this.nowFormItem.timePeriod;
      if (timePeriod == 1) {
        str = "month";
      } else {
        str = "year";
      }
      return str;
    },

    selectionOption() {
      let arr = []
      if (this.topTabIndex == 0) {
        arr = [{
          name: "区域",
          key: "areaId",
          type: "list",
          length: this.optionList.areaId && this.optionList.areaId.length,
          allTxt: "全市",
        },
          {
            name: "物业类型",
            key: "statPropertyType",
            type: this.topTabIndex == 1 ? "list" : "option",
          },
          {
            name: "楼盘",
            key: "houses",
            type: "list",
          },
        ];
      } else if (this.topTabIndex == 1) {
        arr = [{
          name: "区域",
          key: "areaId",
          type: "list",
          length: this.optionList.areaId && this.optionList.areaId.length,
          allTxt: "全市",
        },
          {
            name: "物业类型",
            key: "statPropertyType",
            type: this.topTabIndex == 1 ? "list" : "option",
          },
          {
            name: "户型结构",
            key: "apartment",
            type: "list",
          },
          {
            name: "面积区间",
            key: "statAcreage",
            type: "list",
          },
          {
            name: "价格区间",
            key: "statPrice",
            type: "list",
          },
        ];
      } else if (this.topTabIndex == 2) {
        arr = [{
          name: "区域",
          key: "areaId",
          type: "list",
          length: this.optionList.areaId && this.optionList.areaId.length,
          allTxt: "全市",
        },
          {
            name: "物业类型",
            key: "statPropertyType",
            type: this.topTabIndex == 1 ? "list" : "option",
          },
        ]
      }
      return arr
    },
    // 用来显示筛选文字用
    optionList() {
      return {
        ...this.cityData,
        houses: this.houses,
        areaId: this.areaList,
        propertyType2: this.nowFormItem.propertyTypeList,
        statPropertyType: this.statPropertyTypeList,
        apartment: this.apartmentList,
        statAcreage: this.statAcreageList,
        statPrice: this.statPriceList,
      };
    },

    // 是否显示切换表格展示的选择
    showTableStyle() {
      let val = true;
      let formItem = this.nowFormItem;
      if (this.topTabIndex == 2) val = false;
      if (
          (this.topTabIndex == 1 && formItem.statAcreage.length) ||
          formItem.apartment.length ||
          formItem.statPrice.length
      ) {
        val = false;
      }
      return val;
    },
    // 表格头部 按照指标筛选显示
    tableHeads() {
      let arr = [];
      let formItem = this.nowFormItem;
      let headList = formItem.tableHead;
      if (!headList) return [];

      // 是否勾选同比 环比
      let hasWith =
          headList &&
          headList.some((key) => {
            return key == "同比";
          });
      let hasLoop =
          headList &&
          headList.some((key) => {
            return key == "环比";
          });

      let filterArr = [
        "sellNum",
        "sellArea",
        "stockArea",
        "supplyDeRatio",
        "sellingCycle",
      ];
      this.nowOptionIndex.map((item) => {
        let key = item.key;
        if (key == "with" || key == "loop") return;
        if (
            this.topTabIndex == 1 &&
            filterArr.indexOf(key) > -1 &&
            (formItem.statAcreage.length ||
                formItem.apartment.length ||
                formItem.statPrice.length)
        )
          return;
        if (headList.indexOf(item.title) > -1) {
          arr.push(item);
          if (!item.single) {
            if (hasWith) {
              arr.push({
                key: `${item.key}Yoy`,
                title: `${item.title}同比`,
                minWidth: 120,
                align: "center",
                render: (h, params) => {
                  return h(
                      "span",
                      Number((params.row[`${item.key}Yoy`] * 100).toFixed(
                          2)) +
                      "%"
                  );
                },
              });
            }
            if (hasLoop) {
              arr.push({
                key: `${item.key}Mom`,
                title: `${item.title}环比`,
                minWidth: 120,
                align: "center",
                render: (h, params) => {
                  return h(
                      "span",
                      Number((params.row[`${item.key}Mom`] * 100).toFixed(
                          2)) +
                      "%"
                  );
                },
              });
            }
          }
        }
      });

      if (
          this.topTabIndex == 1 &&
          (formItem.statAcreage.length ||
              formItem.apartment.length ||
              formItem.statPrice.length)
      ) {
        let title = "";
        let key = "";
        if (formItem.statAcreage.length) {
          title = "面积区间";
          key = "areaName";
        }
        if (formItem.apartment.length) {
          title = "户型结构";
          key = "houseTypeName";
        }
        if (formItem.statPrice.length) {
          title = "价格区间";
          key = "priceName";
        }
        arr.splice(3, 0, {
          key,
          title,
          align: "center",
          minWidth: 120,
        });
      }

      return arr;
    },
    filterTableHeads() {
      let item = this.nowFormItem;
      let filterArr = [
        "time",
        "cityTime",
        "housesName",
        "districtName",
        "plateName",
        "diary",
        "regionName",
        "housesTypeName1",
        "propertyTypeName1",
        "propertyTypeName",
        "developersName",
        "with",
        "loop",
      ];

      if (
          this.topTabIndex == 1 &&
          (item.statAcreage.length ||
              item.apartment.length ||
              item.statPrice.length)
      ) {
        filterArr.push(
            ...[
              "sellNum",
              "sellArea",
              "stockArea",
              "supplyDeRatio",
              "sellingCycle",
            ]
        );
      }
      let list = this.tableHeads;
      //添加单位
      return list.filter((item) => {
        return filterArr.indexOf(item.key) == -1;
      });
    },

    // 筛选指标
    nowOptionIndex() {
      let arr = [];

      if (this.topTabIndex == 0) {
        arr = this.itemOneHead;
      } else if (this.topTabIndex == 1) {
        arr = this.itemTwoHead;
      } else if (this.topTabIndex == 2) {
        arr = this.itemThreeHead;
      }

      return arr;
    },
    filterOptionList() {
      let item = this.nowFormItem;
      let filterArr = [
        "time",
        "cityTime",
        "housesName",
        "districtName",
        "plateName",
        "diary",
        "regionName",
        "housesTypeName1",
        "propertyTypeName1",
        "propertyTypeName",
        "developersName",
      ];

      if (
          this.topTabIndex == 1 &&
          (item.statAcreage.length ||
              item.apartment.length ||
              item.statPrice.length)
      ) {
        filterArr.push(
            ...[
              "sellNum",
              "sellArea",
              "stockArea",
              "supplyDeRatio",
              "sellingCycle",
            ]
        );
      }
      return filterArr;
    },
    controlId() {
      switch (this.topTabIndex) {
        case 0:
          return 1;
        case 1:
          return 2;
        default:
          return 3;
      }
    },
    showChoseCompany() {
      let arr = [];
      this.nowFormItem.chooseCityList &&
      this.nowFormItem.chooseCityList.forEach((i) => {
        arr.push(i.name);
      });
      let str = arr.join("，");
      return str;
    },
    selectList2() {
      let arr = [];
      let formItem = this.nowFormItem || this.formItem;
      this.selectionOption.forEach(option => {
        let item = formItem[option.key];
        if (item || item === 0) {
          let {
            type,
            name,
            key
          } = option;
          // 如果是选项列表 要找到对应名字
          if (type == 'option') {
            this.optionList[key].some(obj => {
              if (item == obj.code || item == obj.areaId) {
                item = obj.name;
                return true;
              }
            })
          } else if (type == 'list') {
            let list = this.optionList[key];
            let res = item.map(val => {
              let str = "";
              list.some(obj => {
                if (val == obj.code || val == obj.areaId) {
                  str = obj.name;
                  return true;
                }
              })
              return str;
            });
            item = res;
          }
          arr.push({
            ...option,
            value: item
          })
        }
      })
      return arr;
    }
  },
  watch: {
    selectRenderCity: function (val) {
      this.canvasData && this.canvasData.length && this.setCanvasOption();
    },
    selectRenderType: function (val) {
      this.canvasData && this.canvasData.length && this.setCanvasOption();
    },
    selectRenderIndex: function (val) {
      setTimeout(() => {
        this.canvasData && this.canvasData.length && this.setCanvasOption();
      }, 300);
    },
    selectRenderCom: function (val) {
      this.canvasData && this.canvasData.length && this.setCanvasOption();
    },
    selectRenderDev: function (val) {
      this.canvasData && this.canvasData.length && this.setCanvasOption();
    },
    selectRenderMulCity: function (val) {
      this.canvasData && this.canvasData.length && this.setCanvasOption();
    },
    topTabIndex: function (val) {
      this.reset();
    },
    "nowFormItem.areaId": function (val) {
      if (this.topTabIndex == 0) {
        // this.getHouseList();
      } else if (this.topTabIndex == 2) {
        this.getMultiCom(this.formItem3.multiCityRadio);
      }
    },
    // 'nowFormItem.chooseCityList':function(val){
    //   if(this.topTabIndex == 2 && val){
    //     this.getList();
    //   }
    // }
  },
  beforeMount() {
    this.$Notice.destroy();
  },
  mounted() {
    this.initData();
  },
  destroyed() {
    this.showDiaryManage2 = false;
  },
  methods: {
    hideAlerMsg(){
      this.showAlertMsg = false;
    },
    reset() {
      this.nowFormItem.tableData = []
    },
    dataAlertMsg() {
      getDataCityStatisticsAlertMessagesConfig().then((data) => {
        let alertMessages = data.alertMessages
        this.showAlertMsg = false;
        for (const element of alertMessages) {
          this.alertMsg = element.message
          let nowFormItem = this.nowFormItem
          if (this.cityId == element.cityId) {
            let monthStart = element.monthStart.replaceAll('-', '/')
            let monthEnd = element.monthEnd.replaceAll('-', '/')
            let yearStart = element.yearStart.replaceAll('-', '/')
            let yearEnd = element.yearEnd.replaceAll('-', '/')
            let flag = false;
            let formatTimeStart = nowFormItem.formatTimeStart.replaceAll('-', '/');
            let formatTimeEnd = nowFormItem.formatTimeEnd.replaceAll('-', '/');
            let dms = Date.parse(monthStart)
            let dme = Date.parse(monthEnd)
            let ds = Date.parse(yearStart)
            let de = Date.parse(yearEnd)
            let s = Date.parse(formatTimeStart)
            let e = Date.parse(formatTimeEnd)
            if (nowFormItem.formatTimeStart.length == 4 && nowFormItem.formatTimeEnd.length == 4 && formatTimeStart<= formatTimeEnd) {//年
              if ((s <= ds && ds <= e && e <= de) || (ds <= s && s <= de && ds <= e && e <= de) || (ds <= s && s <= de && de <= e) || (s <= ds && de <= e)) {
                flag = true;
              }
            }
            if (nowFormItem.formatTimeStart.length == 7 && nowFormItem.formatTimeEnd.length == 7  && formatTimeStart<= formatTimeEnd) {
              if ((s <= dms && dms <= e && e <= dme) || (dms <= s && s <= dme && dms <= e && e <= dme) || (dms <= s && s <= dme && dme <= e) || (s <= dms && dme <= e)) {
                flag = true;
              }
            }
            this.showAlertMsg = flag;
            break;
          }
        }
      })
    },

    // 通过id获取名字
    formalName(type, id) {
      let list = this[type] || this.cityData[type];
      let str = "";

      list.map((item) => {
        if (item.code == id) {
          str = item.name || item.title;
        }
      });
      return str;
    },
    async initData() {
      let item = this.formItem;
      let month = this.getLast3Month();
      item.timePeriod = 1;
      item.timeStart = month.last;
      item.timeEnd = month.now;
// item.formatTimeStart =  item.timeStart
//       item.formatTimeEnd =  item.timeEnd
      item.statPropertyType = ['1.1'];
      item.areaId = []
      // this.cityId = '440100000000'
      this.getCitylist().then(res => {
        if (res) {
          this.cityId = res[0].code
          this.choosePageCity(this.cityId)
          this.status2033 = true;
        }
      })
      // 为每个tab分配一个参数一样的formItem
      let obj = JSON.parse(JSON.stringify(this.formItem));
      this.topTabs.map((item, index) => {
        // this.$set(this,`formItem${index+1}`,obj);
        this[`formItem${index + 1}`] = obj;
      });
      // 过滤部分值
      this.formItem2.statPropertyType = ['1.1']
      this.formItem3.statPropertyType = ['1.1']
      this.formItem3.multiCityRadio = 1;
      this.$nextTick(() => {
        this.changeTimePeriod();
        // this.search();
      });
    },
    exportData() {
      if (this.topTabIndex == 0) {
        const param = this.getCityStatSearchData();
        param.current = 1;
        param.size = 10000000;

        let listApi = "";
        if (this.tabIndex == 0) {
          listApi = statistics_page;
          param.reportType = 1
        } else if (this.tabIndex == 1) {
          listApi = get_two_data;
        } else if (this.tabIndex == 2) {
          listApi = get_rent_data;
        }

        listApi(param)
            .then((data) => {
              const tableData = data.page.records;
              let filterVal = [];
              let tHeader = [];
              this.tableHeads.forEach((item) => {
                tHeader.push(item.title);
                filterVal.push(item.key);
              });
              const resData = formatJson(filterVal, tableData);
              downloadData(tHeader, resData, "楼盘统计");
            })
            .finally(() => {
              this.loading = false;
            });
      } else if (this.topTabIndex == 1) {
        const param = this.getRegionStatSearchData();
        param.current = 1;
        param.size = 10000000;

        let listApi = "";
        if (this.tabIndex == 0) {
          listApi = statistics_page;
          param.reportType = 2
        } else if (this.tabIndex == 1) {
          listApi = stat_dis_two;
        } else if (this.tabIndex == 2) {
          listApi = stat_dis_rent;
        }

        listApi(param)
            .then((data) => {
              const tableData = data.page.records;
              let filterVal = [];
              let tHeader = [];
              this.tableHeads.forEach((item) => {
                tHeader.push(item.title);
                filterVal.push(item.key);
              });
              const resData = formatJson(filterVal, tableData);
              downloadData(tHeader, resData, "行政区统计");
            })
            .finally(() => {
              this.loading = false;
            });
      } else {
        const param = this.getDevStatSearchData();
        param.current = 1;
        param.size = 10000000;
        param.reportType = 3
        statistics_page(param)
            .then((data) => {
              const tableData = data.page.records;
              let filterVal = [];
              let tHeader = [];
              this.tableHeads.forEach((item) => {
                tHeader.push(item.title);
                filterVal.push(item.key);
              });
              const resData = formatJson(filterVal, tableData);
              downloadData(tHeader, resData, "开发商统计");
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
    getLast3Month() {
      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1; //0-11表示1-12月
      var day = now.getDate();
      var dateObj = {};
      if (parseInt(month) < 10) {
        month = "0" + month;
      }
      if (parseInt(day) < 10) {
        day = "0" + day;
      }

      dateObj.now = year + "-" + month + "-" + day;

      if (parseInt(month) == 1) {
        //如果是1月份，则取上一年的10月份
        dateObj.last = parseInt(year) - 1 + "-10-" + day;
        return dateObj;
      }
      if (parseInt(month) == 2) {
        //如果是2月份，则取上一年的11月份
        dateObj.last = parseInt(year) - 1 + "-11-" + day;
        return dateObj;
      }
      if (parseInt(month) == 3) {
        //如果是3月份，则取上一年的12月份
        dateObj.last = parseInt(year) - 1 + "-12-" + day;
        return dateObj;
      }
      //开始时间所在月的总天数
      var preSize = new Date(year, parseInt(month) - 3, 0).getDate();
      if (preSize < parseInt(day)) {
        let resultMonth =
            parseInt(month) - 2 < 10 ?
                "0" + parseInt(month) - 2 :
                parseInt(month) - 2;
        dateObj.last = year + "-" + resultMonth + "-01";
        return dateObj;
      }

      if (parseInt(month) <= 10) {
        dateObj.last = year + "-0" + (parseInt(month) - 3);
        return dateObj;
      } else {
        dateObj.last = year + "-" + (parseInt(month) - 3);
        return dateObj;
      }
    },
    changeTopTab(index) {
      this.status20341 = 0;
      if (index == this.topTabIndex) return;
      this.topTabIndex = index;
      if (index == 0) {
        this.tabIndex = 0;
      } else if (index == 2) {
        this.theadType = 1;
      }
      this.$nextTick(() => {
        let formItem = this.nowFormItem;
        this.changeTimePeriod();
        //加载数据
        // if (formItem.tableData.length == 0) {
        // 	if (this.topTabIndex == 2) {
        // 		setTimeout(() => {
        // 			this.getList();
        // 		}, 3000);
        // 	} else {
        // 		this.getList();
        // 	}
        // }
      });
      //切换时把图表重置为第一页
      this.switchThead(1);
    },
    changeTab(index) {
      if (index == this.tabIndex) return;
      this.tabIndex = index;
      this.nowFormItem.currentIndex = 1
    },
    devModalSwitch() {
      this.showDevManage = !this.showDevManage;
    },
    manageModalSwitch() {
      this.showModalManage = !this.showModalManage;
    },
    showDiaryModal(params) {
      this.nowItem = params.row;
      this.diaryId2 = params.row.housesId;
      this.showDiaryManage2 = true;
    },
    saveTemplate() {
      this.saveSearchData = JSON.stringify(this.nowFormItem);
      this.showModalManage = true;
    },
    chooseTemplate(item) {
      let formItem = this.nowFormItem;
      let params = JSON.parse(item.templateParams);
      // formItem = params;
      for (let key in params) {
        formItem[key] = params[key];
      }
      formItem.currentIndex = 1;
      // this.getList();
    },

    // 切换表格形式（表格 图表）
    switchThead(value) {
      let nowFormItem = this.nowFormItem;
      this.theadType = value;
      if (value == 2) {
        setTimeout(() => {
          this.renderCanvans();
        }, 300);
      } else {
        //初始化数据
        if (this.chart) {
          this.chart.clear();
        }
        // setTimeout(() => {
        // 	this.getList();
        // }, 300);
      }
    },
    initCanvasFilter() {
      let formItem = this.nowFormItem;
      if (!this.selectRenderIndex) {
        // 如果有选中成交面积 默认显示成交面积
        if (
            this.filterTableHeads.some((item) => item.title == "成交面积(㎡)")
        ) {
          this.selectRenderIndex = "成交面积(㎡)";
        } else {
          this.selectRenderIndex = this.filterTableHeads[0].title;
        }
      }
      // 初始化楼盘
      if (this.topTabIndex == 0) {
        this.firstHouse = false;
        // 如果没有选中值 初始化选中
        if (!formItem.houses.length.length) {
          this.firstHouse = true;
          // let list = this.houses.slice(0, 8);
          // this.selectRenderDev = list.map(item => item.id);
        } else if (formItem.houses.length) {
          this.selectRenderDev = formItem.houses.slice(0, 8);
        }
      }
      // 初始化行政区
      if (this.topTabIndex == 1 && !this.selectRenderCity) {
        if (formItem.areaId && formItem.areaId.length) {
          this.selectRenderCity = formItem.areaId[0];
        } else {
          this.selectRenderCity = this.areaList[0].code;
        }
      }
      // 初始化开发商
      if (this.topTabIndex == 2) {
        // 初始化行政区
        if (this.selectRenderMulCity.length == 0) {
          if (formItem.areaId && formItem.areaId.length) {
            this.selectRenderMulCity = formItem.areaId;
          } else {
            this.selectRenderMulCity = this.areaList[0].code;
          }
        }
        if (this.selectRenderCom == "" && formItem.chooseCityList.length) {
          this.selectRenderCom = formItem.chooseCityList[0].code;
        }
      }
    },
    // 绘制图表
    renderCanvans() {
      let formItem = this.nowFormItem;
      this.initCanvasFilter();
      this.chart = echarts.init(document.getElementById("canvas"));
      this.chart.clear();
      let xData = [];
      let yData = [];
      let withData = [];
      let loopData = [];
      let houseData = {};
      let filterArr = [];
      let filterData = {};
      let devData = {};
      let devList = [];
      let listApi = "";
      const data = this.getSearchData(-1);
      if (this.topTabIndex == 0) {
        listApi = statistics_page;
        data.reportType = 1
      } else if (this.topTabIndex == 1) {
        listApi = statistics_page;
        data.reportType = 2
      } else if (this.topTabIndex == 2) {
        listApi = statistics_page;
        data.reportType = 3
        if (!this.selectRenderCom) {
          // this.$Message.warning("请选择开发商");
          this.$msg.error({
            type: "remind",
            code: 4019,
          });

          return;
        }
      }
      listApi(data).then((data) => {
        // this.tableHead = data.dynamicRecord.heads;

        // if(this.topTabIndex == 0){
        //     this.canvasData = data.page.records;
        //     }else{
        //         this.canvasData = data.records;
        //     }
        this.canvasData = data.page.records;
        let unit = this.selectRenderIndex;
        if (this.firstHouse) {
          this.selectRenderDev = this.canvasData.slice(0, 8).map((item) => {
            return item.housesId;
          });
        }

        let isFilter = false;

        //按时间排序
        this.canvasData.sort((a, b) => {
          return new Date(a.cityTime) - new Date(b.cityTime);
        });

        this.canvasData.map((item) => {
          let selectKey = "";
          this.tableHeads.map((item) => {
            if (item.title == this.selectRenderIndex) {
              selectKey = item.key;
            }
          });

          if (this.topTabIndex == 0) {
            if (this.selectRenderDev.indexOf(item.housesId) == -1) return;
            houseData[item.housesId] = item[selectKey];
          }
          if (this.topTabIndex == 1) {
            if (
                this.formalName("areaList", this.selectRenderCity) !=
                item.regionName
            )
              return;

            let filterKey = "";
            let filerTitle = "";
            if (formItem.statAcreage.length) {
              isFilter = true;
              filerTitle = "面积区间";
              filterKey = "areaName";
            }
            if (formItem.apartment.length) {
              isFilter = true;
              filerTitle = "户型结构";
              filterKey = "houseTypeName";
            }
            if (formItem.statPrice.length) {
              isFilter = true;
              filerTitle = "价格区间";
              filterKey = "priceName";
            }
            if (!filterData[item.cityTime]) {
              let withVal = Number((item[`${selectKey}Yoy`] * 100).toFixed(2));
              let loopVal = Number((item[`${selectKey}Mom`] * 100).toFixed(2));
              xData.push(item.cityTime);
              yData.push(item[selectKey]);
              withData.push(withVal);
              loopData.push(loopVal);

              filterData[item.cityTime] = {
                withVal,
                loopVal,
                total: item[selectKey],
              };
              if (isFilter) {
                let filterItem = item[filterKey];
                filterData[item.cityTime][filterItem] = item[selectKey];
                if (filterArr.indexOf(filterItem) == -1) {
                  filterArr.push(filterItem);
                }
              }
            } else {
              let now = filterData[item.cityTime];
              let withVal = Number((item[`${selectKey}Yoy`] * 100).toFixed(2));
              let loopVal = Number((item[`${selectKey}Mom`] * 100).toFixed(2));

              now.withVal += withVal;
              now.loopVal += loopVal;
              now.total += item[selectKey];
              if (isFilter) {
                let filterItem = item[filterKey];
                now[filterItem] = item[selectKey];
                if (filterArr.indexOf(filterItem) == -1) {
                  filterArr.push(filterItem);
                }
              }
            }
          }


          if (this.topTabIndex == 2) {
            if (this.selectRenderCom != item.developersId) return;
            if (this.selectRenderMulCity.indexOf(item.region) == -1) return;

            if (!devData[item.regionName]) {
              devData[item.regionName] = 0;
              devList.push(item.regionName);
            }
            devData[item.regionName] = item[selectKey];
          }
        });
        // var myChart = this.$echarts.init(document.querySelectorAll('.canvas-box'));
        this.canvasOption = {
          // backgroundColor: "#011c3a",
          color: [
            "rgb(0,176,240)",
            "rgb(255,196,0)",
            "rgb(255,0,0)",
            "rgb(146,208,80)",
            "rgb(255,255,0)",
            "rgb(112,48,160)",
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
          },
          grid: {
            bottom: 120
          },

          legend: {
            left: "center",
            bottom: "0",
            // data: [unit, "同比增长", "环比增长"]
            data: [unit],
          },
          xAxis: {
            data: xData,
            axisLine: {
              lineStyle: {
                // color: "#0177d4"
              },
            },
            axisLabel: {
              interval: 0,
              fontSize: 14,
              rotate: 45, //倾斜度 -90 至 90 默认为0
              margin: 2,
            },
          },
          yAxis: [{
            name: unit,
            nameTextStyle: {
              fontSize: 16,
            },
            axisLabel: {
              fontSize: 16,
            },
            splitLine: {
              show: false,
            },
            minInterval: 1,
          },],
          series: [{
            type: "bar",
            name: unit,
            barWidth: 18,
            data: yData,
            yAxisIndex: 0,
          },],
        };
        if (this.topTabIndex == 0) {
          let chooseList = this.selectRenderDev;
          this.canvasOption.legend = [];
          this.canvasOption.xAxis.data = chooseList.map((id) =>
              this.formalName("houses", id)
          );
          this.canvasOption.series[0].data = chooseList.map((id) => {
            return houseData[id] || 0;
          });
        }
        if (this.topTabIndex == 1) {
          // this.canvasOption.yAxis.push({
          // 	type: "value",
          // 	scale: true,
          // 	name: "百分比",
          // 	splitLine: {
          // 		show: false
          // 	},
          // 	axisLabel: {
          // 		formatter: "{value} %"
          // 	}
          // });
          if (isFilter) {
            this.canvasOption.legend.data = filterArr;
            this.canvasOption.series = filterArr.map((item) => {
              return {
                type: "bar",
                name: item,
                barWidth: 18,
                stack: "sum",
                data: xData.map((time) => {
                  return filterData[time][item];
                }),
                yAxisIndex: 0,
              };
            });
          }

          // this.canvasOption.series.push(
          // 	{
          // 		name: "同比增长",
          // 		data: withData,
          // 		type: "line",
          // 		smooth: true,
          // 		symbol: "none",
          // 		yAxisIndex: 1
          // 	},
          // 	{
          // 		name: "环比增长",
          // 		data: loopData,
          // 		type: "line",
          // 		smooth: true,
          // 		symbol: "none",
          // 		yAxisIndex: 1
          // 	}
          // );
        }
        if (this.topTabIndex == 2) {
          this.canvasOption.legend = [];
          this.canvasOption.xAxis.data = devList;
          this.canvasOption.series[0].data = devList.map(
              (item) => devData[item]
          );
        }
        this.chart.setOption(this.canvasOption);
      });
    },
    setCanvasOption() {
      let formItem = this.nowFormItem;
      let xData = [];
      let yData = [];
      let withData = [];
      let loopData = [];

      let houseData = {};
      let houseList = [];

      let filterArr = [];
      let filterData = {};

      let devData = {};
      let devList = [];

      let unit = this.selectRenderIndex;

      let isFilter = false;
      this.canvasData.map((item) => {
        let selectKey = "";
        this.tableHeads.map((item) => {
          if (item.title == this.selectRenderIndex) {
            selectKey = item.key;
          }
        });
        if (this.topTabIndex == 0) {
          if (this.selectRenderDev.indexOf(item.housesId) == -1) return;
          houseData[item.housesId] = item[selectKey];
        }

        if (this.topTabIndex == 1) {
          if (
              this.formalName("areaList", this.selectRenderCity) !=
              item.regionName
          )
            return;

          let filterKey = "";
          let filerTitle = "";
          if (formItem.statAcreage.length) {
            isFilter = true;
            filerTitle = "面积区间";
            filterKey = "areaName";
          }
          if (formItem.apartment.length) {
            isFilter = true;
            filerTitle = "户型结构";
            filterKey = "houseTypeName";
          }
          if (formItem.statPrice.length) {
            isFilter = true;
            filerTitle = "价格区间";
            filterKey = "priceName";
          }
          if (!filterData[item.cityTime]) {
            let withVal = Number((item[`${selectKey}Yoy`] * 100).toFixed(2));
            let loopVal = Number((item[`${selectKey}Mom`] * 100).toFixed(2));
            xData.push(item.cityTime);
            yData.push(item[selectKey]);
            withData.push(withVal);
            loopData.push(loopVal);

            filterData[item.cityTime] = {
              withVal,
              loopVal,
              total: item[selectKey],
            };
            if (isFilter) {
              let filterItem = item[filterKey];
              filterData[item.cityTime][filterItem] = item[selectKey];
              if (filterArr.indexOf(filterItem) == -1) {
                filterArr.push(filterItem);
              }
            }
          } else {
            let now = filterData[item.cityTime];
            let withVal = Number((item[`${selectKey}Yoy`] * 100).toFixed(2));
            let loopVal = Number((item[`${selectKey}Mom`] * 100).toFixed(2));

            now.withVal += withVal;
            now.loopVal += loopVal;
            now.total += item[selectKey];
            if (isFilter) {
              let filterItem = item[filterKey];
              now[filterItem] = item[selectKey];
              if (filterArr.indexOf(filterItem) == -1) {
                filterArr.push(filterItem);
              }
            }
          }
        }

        if (this.topTabIndex == 2) {
          if (this.selectRenderCom != item.developersId) return;
          if (this.selectRenderMulCity.indexOf(item.region) == -1) return;

          if (!devData[item.regionName]) {
            devData[item.regionName] = 0;
            devList.push(item.regionName);
          }
          devData[item.regionName] = item[selectKey];
        }
        withData.push(Number((item[`${selectKey}Yoy`] * 100).toFixed(2)));
        loopData.push(Number((item[`${selectKey}Mom`] * 100).toFixed(2)));
      });


      this.canvasOption.yAxis[0].name = unit;
      this.canvasOption.series[0].name = unit;
      if (this.topTabIndex == 0) {
        let chooseList = this.selectRenderDev;
        this.canvasOption.legend = [];
        this.canvasOption.xAxis.data = chooseList.map((id) =>
            this.formalName("houses", id)
        );
        this.canvasOption.series[0].data = chooseList.map((id) => {
          return houseData[id] || 0;
        });
      } else if (this.topTabIndex == 2) {
        this.canvasOption.legend = [];
        this.canvasOption.xAxis.data = devList;
        this.canvasOption.series[0].data = devList.map((item) => devData[item]);
      } else {
        if (isFilter) {
          this.canvasOption.legend.data = filterArr;
          this.canvasOption.series = filterArr.map((item) => {
            return {
              type: "bar",
              name: item,
              barWidth: 18,
              stack: "sum",
              data: xData.map((time) => {
                return filterData[time][item];
              }),
              yAxisIndex: 0,
            };
          });
        } else {
          this.canvasOption.xAxis.data = xData;
          this.canvasOption.legend.data[0] = unit;
          this.canvasOption.series = [{
            type: "bar",
            name: unit,
            barWidth: 18,
            data: yData,
            yAxisIndex: 0,
          },];
          // this.canvasOption.series[1].data = withData;
          // this.canvasOption.series[2].data = loopData;
        }
      }
      this.chart.setOption(this.canvasOption);
    },

    // 选择时间操作
    changeTimePeriod() {
      let item = this.nowFormItem;
      item.timeStart && this.changeTime(1);
      item.timeEnd && this.changeTime(2);
    },
    checkTime() {
      if (
          this.nowFormItem.timePeriod &&
          this.nowFormItem.timeStart &&
          this.nowFormItem.timeEnd
      ) {
        if (this.nowFormItem.timePeriod == 2) {
          if (this.nowFormItem.quarterStart && this.nowFormItem.quarterEnd) {
            return (this.nowFormItem.optionTime =
                    `${this.nowFormItem.formatTimeStart}${this.nowFormItem.quarterStart}至${this.nowFormItem.formatTimeEnd}${this.nowFormItem.quarterEnd}`
            );
          } else {
            return (this.nowFormItem.optionTime = "");
          }
        } else {
          return (this.nowFormItem.optionTime =
              `${this.nowFormItem.formatTimeStart}至${this.nowFormItem.formatTimeEnd}`);
        }
      }
      return (this.nowFormItem.optionTime = "");
    },
    changeTime(type) {
      let nowFormItem = this.nowFormItem;


      let timeStart = nowFormItem.timeStart;
      let timeEnd = nowFormItem.timeEnd;
  timeStart = new Date(timeStart);
  timeEnd = new Date(timeEnd);

      type == 1 &&
      (() => {
        if (typeof timeStart != "object") {

          if (timeStart.indexOf("上午") || timeStart.indexOf("下午")) {
            timeStart = localeDateStringtoTimestamp(timeStart);

          } else {

            timeStart = new Date(timeStart);
          }
        }
        if (nowFormItem.timePeriod == 1 && timeStart) {
          nowFormItem.formatTimeStart = `${timeStart.getFullYear()}-${
              timeStart.getMonth() + 1 < 10
                  ? `0${timeStart.getMonth() + 1}`
                  : timeStart.getMonth() + 1
          }`;
        } else {

          nowFormItem.formatTimeStart = timeStart ?`${timeStart.getFullYear()}` :"";
        }
      })();
      type == 2 &&
      (() => {
        if (typeof timeEnd != "object") {
          if (timeEnd.indexOf("上午") || timeEnd.indexOf("下午")) {
            timeEnd = localeDateStringtoTimestamp(timeEnd);
          } else {
            timeEnd = new Date(timeEnd);
          }
        }
        if (nowFormItem.timePeriod == 1 && timeEnd) {
          nowFormItem.formatTimeEnd = `${timeEnd.getFullYear()}-${
              timeEnd.getMonth() + 1 < 10
                  ? `0${timeEnd.getMonth() + 1}`
                  : timeEnd.getMonth() + 1
          }`;
        } else {
          nowFormItem.formatTimeEnd = timeEnd ?`${timeEnd.getFullYear()}`:"";
        }
      })();
      this.checkTime();
      this.dataAlertMsg();
    },

    // 其他页面选择操作
    addList(list) {
      this.nowFormItem.chooseCityList = list;
    },
    getInfo(arr, code) {
      let item
      arr.forEach(i => {
        if (i.code == code) {
          item = i
        }
      })
      return item
    },
    choosePageCity(cityId) {
      this.getCategoryList('statPropertyTypeList', 1)
      this.getCategoryList('apartmentList', 2)
      this.getCategoryList('statAcreageList', 3)
      this.getCategoryList('statPriceList', -1)
      this.nowFormItem.currentIndex = 1
      let nowFormItem = this.nowFormItem;
      let cityInfo = this.getInfo(this.cityList, cityId)
      this.cityName = cityInfo.name
      this.cityId = cityId
      this.getHouseList();
      developerMap({
        cityName: this.cityName
      }).then((data) => {
        this.kfs = data;
      });
      districtMap({
        cityCode: cityId,
      }).then((data) => {
        this.areaList = data.map((item) => {
          return {
            code: item.code,
            name: item.name,
          };
        });

        this.topTabs.map((item, index) => {
          // let obj = JSON.parse(JSON.stringify(this.formItem));
          // this.$set(this,`formItem${index+1}`,obj);
          this[`formItem${index + 1}`].areaId = this.areaList.map(
              (item) => item.code
          );
        });
      });


      if (this.status2033) {

        this.status2033 = false;
      }
      this.dataAlertMsg();
    },
    getHouseList(cityId = this.cityId) {
      let formItem = this.formItem1;
      let params = {
        cityCode: cityId,
        // districtCode: formItem.areaId
      }
      lpMap(params).then((data) => {
        this.houses = data.map((item) => {
          return {
            code: item.code,
            name: item.name,
          };
        });
      });
    },
    selectArea(id) {
      let item = this.nowFormItem;
      // if(item.areaId == id) return;
      item.areaId = id;
    },
    optionCustom(type) {
    },
    optionFilter(val, option) {
      let str = "";
      let list = this.cityData[option] || this[option];
      list.some((item) => {
        if (item.code == val) {
          str = item.name;
          return true;
        }
      });
      return str;
    },
    radioOptionChange() {
      let item = this.nowFormItem;
      item.apartment = [];
      item.statAcreage = [];
      item.statPrice = [];
    },
    chooseOption(val) {
      let item = this.nowFormItem;
      if (item.chooseOption != val) {
        setTimeout(() => {
          item.chooseOption = val;
          item.apartment = [];
          item.statAcreage = [];
          item.statPrice = [];
        }, 100);
      } else {
        setTimeout(() => {
          item.chooseOption = "";
          item.apartment = [];
          item.statAcreage = [];
          item.statPrice = [];
        }, 100);
      }
    },

    // 开发商选择
    getMultiCom(key) {
      let formItem = this.formItem3;
      formItem.chooseCityList = [];
      formItem.searchCity = "";
      formItem.filterCityList = [];

      if (key == 0) {
        formItem.multiCityList = [];
        dimHouseDimCompanyJt(this.cityId).then((res) => {
          res.forEach((city) => {
            formItem.multiCityList.push({
              id: city.qyId,
              name: city.qymc,
            });
          });
        });
      } else if (key == 1) {
        formItem.multiCityList = [];
        developerMap({
          cityName: this.cityName
        }).then((res) => {
          res.forEach((city) => {
            formItem.multiCityList.push({
              code: city.code,
              name: city.name,
            });
          });
        });
        // dimHouseDimCompany(this.cityId).then((res) => {
        // 	res.forEach((city) => {
        // 		formItem.multiCityList.push({
        // 			id: city.id,
        // 			name: city.company,
        // 		});
        // 	});
        // 	let indexArr = [1444, 1659];
        // 	let arr = formItem.multiCityList;
        // 	let itemsProcessed = 0;
        // 	arr.forEach((item, index, array) => {
        // 		if (indexArr.includes(item.id)) {
        // 			this.chooseCity(item);
        // 			itemsProcessed++;
        // 		}
        // 	});
        // 	if (itemsProcessed === indexArr.length) {
        // 		this.getList();
        // 	}
        // });
      }
    },
    searchCom() {
      let nowFormItem = this.nowFormItem;
      let val = nowFormItem.searchCity;
      let reg = new RegExp(val);

      let arr = nowFormItem.multiCityList.filter((item) => {
        return reg.test(item.name);
      });

      if (val) {
        nowFormItem.filterCityList = arr;
      } else {
        nowFormItem.filterCityList = [];
      }
    },
    chooseCity(item) {
      if (this.nowFormItem.chooseCityList.some((i) => i.code == item.code)) return;
      // if (item.checked) return;
      // item.checked = true;
      this.nowFormItem.chooseCityList.push(item);
    },
    delCity(item, index) {
      item.checked = false;
      this.nowFormItem.chooseCityList.splice(index, 1);
    },
    clearChooseCity() {
      let item = this.nowFormItem;
      item.chooseCityList.forEach((item) => {
        item.checked = false;
      });
      item.chooseCityList = [];
    },
    mutliSelectAll() {
      if (this.nowFormItem.filterCityList.length) {
        this.nowFormItem.filterCityList.forEach((item) => {
          this.chooseCity(item);
        });
      } else {
        this.nowFormItem.multiCityList.forEach((item) => {
          this.chooseCity(item);
        });
      }
    },

    // 已选提交的操作
    delTime() {
      let item = this.nowFormItem;
      item.timePeriod = "";
      item.quarterStart = "";
      item.quarterEnd = "";
      item.timeStart = "";
      item.timeEnd = "";
      item.optionTime = "";
    },
    info1() {

      this.status20341++;
    },
    search() {
      if (this.loading) return;
      if ( this.nowFormItem.statPropertyType.length==0) {
        this.$msg.error(
					{
						text: "请选择物业类型",
					}
				);
        return
      }
	if (!this.cityId) {
				this.$msg.error(
					{
						text: "请选择城市",
					}

				);

				return
			}
      this.nowFormItem.currentIndex = 1;
      this.getList();

    },
    // 全选checkbox用
    handleCheckAll(val, list, key) {
      let item = this.nowFormItem;
      // 已经全选
      if (val) {
        item[key] = [];
      } else {
        if (key == "indexOptions") {
          item[key] = Object.keys(list);
        } else {
          item[key] = list.map((item) => {
            return item.code || item.key;
          });
        }
      }
    },

    getSearchData(size) {

      if (this.topTabIndex == 0) {
        return this.getCityStatSearchData(size);
      } else if (this.topTabIndex == 1) {
        return this.getRegionStatSearchData(size);
      } else if (this.topTabIndex == 2) {
        return this.getDevStatSearchData(size);
      }
    },
    getCityStatSearchData(size) {
      let item = Object.assign({}, this.nowFormItem);
      let params = {
        size: size == -1 ? 100000000 : this.pageSize,
        current: size == -1 ? 1 : item.currentIndex,
        cityTimes: item.optionTime && item.optionTime.split("至"),
        timePeriod: item.timePeriod,
        propertyType: item.statPropertyType,
        csId: this.cityId,
        district: item.areaId ? item.areaId : "",
        housesId: item.houses,
      };
      return params;
    },
    getRegionStatSearchData(size) {
      let item = Object.assign({}, this.nowFormItem);
      let params = {
        size: size == -1 ? 100000000 : this.pageSize,
        current: size == -1 ? 1 : item.currentIndex,
        cityTimes: item.optionTime && item.optionTime.split("至"),
        timePeriod: item.timePeriod,
        csId: this.cityId,
        district: item.areaId ? item.areaId : "",
        propertyType: item.statPropertyType,
        detailType: item.propertyType,
        area: item.statAcreage,
        housesType: item.apartment,
        price: item.statPrice,
        hb: this.optionIndex == "2" || size ? size != -1 : false,
        // housesType : this.cityId && [this.cityId],
      };
      return params;
    },
    getDevStatSearchData(size) {
      let item = Object.assign({}, this.nowFormItem);
      let params = {
        size: size == -1 ? 100000000 : this.pageSize,
        current: size == -1 ? 1 : item.currentIndex,
        cityTimes: item.optionTime && item.optionTime.split("至"),
        timePeriod: item.timePeriod,
        csId: this.cityId,
        // region: item.areaId ? item.areaId : "",
        // housesId: item.houses,
        propertyType: item.statPropertyType,
        developersName: item.chooseCityList.length ?
            item.chooseCityList.map((item) => {
              return item.code;
            }) : "",
        // housesType : this.cityId && [this.cityId],
      };
      return params;
    },
    getList() {
      let item = this.nowFormItem;
      if (
          (this.topTabIndex == 1 && item.apartment.length) ||
          item.statPrice.length ||
          item.statAcreage.length
      ) {
        this.theadType = 1;
      }
      if (this.theadType == 2) {
        this.renderCanvans();
        return;
      }

      this.loading = true;
      item.tableData = [];

      if (this.topTabIndex == 0) {
        this.cityStatList();

      } else if (this.topTabIndex == 1) {
        this.regionStatList();

      } else {
        this.devStatList();

      }
      // new_house(param).then(data => {
      // 	item.total = data.total;
      // 	item.tableData = data.records;
      // }).finally(() => {
      // 	this.loading = false;
      // })
    },
    checkData(param) {
      //检测时间及时间周期
      if (!this.nowFormItem.timeStart) {
        this.$msg.error({
          type: "remind",
          code: 4011,
        });

        this.loading = false;
        return false;
      }
      if (!this.nowFormItem.timeEnd) {
        this.$msg.error({
          type: "remind",
          code: 4012,
        });

        this.loading = false;
        return false;
      }
      if (!checkDate(param.cityTimes[0], param.cityTimes[1])) {
        this.loading = false;
        this.$msg.error({
          type: "remind",
          code: 4015,
        });

        return false;
      }
      //topTabindex 为2时
      if (this.topTabIndex == 2) {
        if (!param.propertyType) {
          this.loading = false;
          this.$msg.error({
            type: "remind",
            code: 4018,
          });
          return false;
        }
        if (!param.developersName) {
          this.loading = false;
          this.$msg.error({
            type: "remind",
            code: 4017,
          });
          return false;
        }
      } else {
        //topTabindex 为0与1时
        if (!param.propertyType) {
          this.loading = false;
          this.$msg.error({
            type: "remind",
            code: 4018,
          });
          return false;
        }
        if (param.district.length == 0) {
          this.loading = false;

          this.$msg.error({
            type: "remind",
            code: 4013,
          });

          return false;
        }
        //topTabindex 为1时
        if (this.topTabIndex == 1 && param.propertyType.length == 0) {
          this.loading = false;
          this.$msg.error({
            type: "remind",
            code: 4018,
          });

          return false;
        }
      }

      return true;
    },
    cityStatList() {
      let item = this.nowFormItem;
      const param = this.getSearchData();
      if (!this.checkData(param)) return;
      let listApi = "";
      if (this.tabIndex == 0) {
        listApi = statistics_page;
        param.reportType = 1
      } else if (this.tabIndex == 1) {
        listApi = get_two_data;
      } else if (this.tabIndex == 2) {
        listApi = get_rent_data;
      }
      listApi(param)
          .then((data) => {
            if (this.tabIndex == 0) {
              //过滤表一的空值
              let filterArr = ["with", "loop", "sellingCycle", "supplyDeRatio"];
              this.itemHeadFilterArr = data.isNullColumnNames;
              filterArr = filterArr.concat(this.itemHeadFilterArr);
              this.formItem1.tableHead = this.itemOneHead
                  .filter((item) => {
                    return filterArr.indexOf(item.key) == -1;
                  })
                  .map((item) => item.title);
            }

            item.total = data.page.total;
            item.tableData = data.page.records.map((item) => {
              item.sellArea = this.toFixed(item.sellArea);
              item.bargainArea = this.toFixed(item.bargainArea);
              item.bargainAvgPrice = this.toFixed(item.bargainAvgPrice);
              item.bargainMoney = this.toFixed(item.bargainMoney);
              item.stockArea = this.toFixed(item.stockArea);
              item.sellingCycle = this.toFixed(item.sellingCycle);
              item.housesTypeName1 = this.formalName(
                  "statPropertyType",
                  this.nowFormItem.statPropertyType
              );
              return item;
            });
          })
          .finally(() => {
            this.loading = false;
            this.info1();
          });
    },
    regionStatList() {
      let item = this.nowFormItem;
      const param = this.getSearchData();
      if (!this.checkData(param)) return;
      let listApi = "";
      if (this.tabIndex == 0) {
        listApi = statistics_page;
        param.reportType = 2
      } else if (this.tabIndex == 1) {
        listApi = stat_dis_two;
      } else if (this.tabIndex == 2) {
        listApi = stat_dis_rent;
      }

      listApi(param)
          .then((data) => {
            if (this.tabIndex == 0) {
              let filterArr = ["with", "loop"];
              this.itemHeadFilterArr = data.isNullColumnNames;
              filterArr = filterArr.concat(this.itemHeadFilterArr);
              this.formItem2.tableHead = this.itemTwoHead
                  .filter((item) => {
                    return filterArr.indexOf(item.key) == -1;
                  })
                  .map((item) => item.title);
            }

            item.total = data.page.total;
            item.tableData = data.page.records.map((item) => {
              item.sellArea = this.toFixed(item.sellArea);
              item.bargainArea = this.toFixed(item.bargainArea);
              item.bargainAvgPrice = this.toFixed(item.bargainAvgPrice);
              item.bargainMoney = this.toFixed(item.bargainMoney);
              item.stockArea = this.toFixed(item.stockArea);
              item.sellingCycle = this.toFixed(item.sellingCycle);

              item.propertyTypeName1 = item.propertyTypeName;
              if (this.nowFormItem.statPropertyType[0] == '1.1') {
                item.propertyTypeName1 = "商品房";
              }
              return item;
            });
          })
          .finally(() => {
            this.loading = false;
            this.info1();
          });
    },
    devStatList() {
      let item = this.nowFormItem;
      const param = this.getSearchData();
      if (!this.checkData(param)) return;
      param.reportType = 3
      statistics_page(param)
          .then((data) => {
            let filterArr = [
              "sellNum",
              "sellArea",
              "stockArea",
              "with",
              "loop",
              "sellingCycle",
              "supplyDeRatio",
            ];
            this.itemHeadFilterArr = data.isNullColumnNames;
            filterArr = filterArr.concat(this.itemHeadFilterArr);

            this.formItem3.tableHead = this.itemThreeHead
                .filter((item) => {

                  return filterArr.indexOf(item.key) == -1;
                })
                .map((item) => item.title);

            item.total = data.page.total;
            item.tableData = data.page.records.map((item) => {
              item.sellArea = this.toFixed(item.sellArea);
              item.bargainArea = this.toFixed(item.bargainArea);
              item.bargainAvgPrice = this.toFixed(item.bargainAvgPrice);
              item.bargainMoney = this.toFixed(item.bargainMoney);
              item.stockArea = this.toFixed(item.stockArea);
              item.sellingCycle = this.toFixed(item.sellingCycle);

              return item;
            });
          })
          .finally(() => {
            this.loading = false;
            this.info1();
          });
    },

    pageSizeChange(size) {
      this.nowFormItem.currentIndex = 1;
      this.pageSize = size;
      this.getList();
    },
    pageChange(size) {
      this.nowFormItem.currentIndex = size;
      let target = document.querySelector(".ivu-table-body");
      target && (target.scrollTop = 0);
      this.getList();
    },
    async getCategoryList(arrayName, type) {
      let params = {
        categoryCode: type,
        cityCode: this.cityId,
      }
      let data = await listByCategoryCode(params)
      this[arrayName] = data
    },
    async getCitylist() {
      return new Promise(async (res, rej) => {
        let params = {
        }
        let data = await cityMap(params)
        if (data) {
          this.cityList = data
          res(data)
        }

      })

    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style/common.scss";

.page {
  width: 100%;
  min-height: calc(100vh - 80px);
  min-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px 0;
  background: #fff;
  overflow: hidden;
  color: #333;
  font-size: 14px;

  .top-line {
    @include flex(flex-start);
    margin-bottom: 10px;

    .line-tabs {
      @include flex(flex-start);
      width: 100%;
      height: 42px;
      margin-left: 12px;
      color: #333;
      font-size: 18px;
      background-color: #b6d4fd;
      cursor: pointer;

      .tab-item {
        @include flex(flex-start);
        height: 100%;
        padding: 0 20px;
        border-right: 1px solid #fff;

        &.active {
          font-weight: bold;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }

  .tab {
    @include flex(flex-start);
    padding-left: 15px;
    font-size: 18px;
    border-bottom: 2px solid #00b6ff;

    .tab-item {
      @include flex();
      position: relative;
      width: 116px;
      height: 48px;
      margin-right: 4px;
      background-color: #e3e6ef;
      border: 2px solid transparent;
      border-bottom: none;
      cursor: pointer;

      &.active {
        color: #00b6ff;
        background-color: #fff;
        border-color: #00b6ff;

        &::after {
          position: absolute;
          width: 100%;
          height: 2px;
          left: 0;
          bottom: -2px;
          z-index: 2;
          background-color: #fff;
          content: "";
        }
      }
    }
  }

  .option-box {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #e3e3e5;

    .ivu-form-item {
      @include flex(flex-start);

      /deep/ .ivu-form-item-label {
        flex-shrink: 0;
        width: 70px;
        text-align: right;
      }

      .option-select {
        float: left;
        margin-right: 20px;
        cursor: pointer;

        &.active {
          color: #00b6ff;
        }
      }
    }

    // /deep/ .ivu-form-item-content {
    //   // @include flex(flex-start);
    // }
    .custom-btn {
      @include flex;
      color: #00b6ff;
      cursor: pointer;
    }

    .radio-option {
      @include flex(flex-start);

      /deep/ .ivu-radio-wrapper {
        @include flex(flex-start);

        // margin-bottom: 24px;
        .ivu-form-item {
          margin-bottom: 0;

          .ivu-form-item-label {
            width: auto;
          }
        }
      }
    }

    .filter-item {
      position: relative;

      // .item-title {
      // }
      .item-ct {
        display: none;
        position: absolute;
        width: 400px;
        left: -20px;
        z-index: 2;
        padding: 8px 16px;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
        overflow: auto;

        .ivu-checkbox-group {
          white-space: normal;
        }
      }

      &:hover {
        .item-ct {
          display: block;
        }
      }
    }

    .areaTransfer {
      display: flex;
      align-items: center;

      .leftTransfer,
      .rightTransfer {
        width: 300px;
        height: 207px;
        border: 1px solid rgba(190, 199, 219, 1);
        border-radius: 5px;
      }

      .leftTransfer {
        background: rgba(246, 249, 255, 0.6);
        padding: 8px;
        box-sizing: border-box;

        .leftTransferBottom {
          display: flex;
          height: 156px;
        }

        .leftRadio {
          flex: 1;
          overflow: auto;

          .ivu-radio-group {
            display: block;
          }

          .ivu-radio-wrapper {
            display: block;
            margin-bottom: 5px;
            color: #333333;
          }
        }

        .rightBox {
          width: 200px;
          height: 156px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(228, 228, 228, 1);
          border-radius: 5px;
          padding: 8px 0;
          box-sizing: border-box;
          overflow: auto;

          .item {
            width: 100%;
            font-size: 16px;
            text-indent: 10px;
            white-space: nowrap;
            cursor: pointer;
            color: #333333;

            .active {
              color: #4373e8;
            }

            &:hover {
              color: #4373e8;
            }
          }
        }
      }

      .leftTransferSearch {
        @include flex;
        margin-bottom: 5px;

        .ivu-input-wrapper {
          width: 220px;
        }

        .btn-all {
          margin-left: auto;
          margin-right: 5px;
          cursor: pointer;
        }
      }

      .centerBtn {
        margin: 0 14px;
        cursor: pointer;
      }

      .rightTransfer {
        border: 1px solid rgba(228, 228, 228, 1);
        overflow: hidden;

        .topBtn {
          width: 100%;
          height: 36px;
          background: #f6f9ff;
          display: flex;
          padding: 0 15px;
          box-sizing: border-box;
          align-items: center;
          justify-content: space-between;

          span {
            color: #999999;
            cursor: pointer;

            &:hover {
              color: #333;
            }
          }
        }

        .itemBox {
          width: 100%;
          padding: 0 15px;
          box-sizing: border-box;
          overflow: auto;
          height: 170px;

          .ivu-tag {
            background: #ededed !important;
          }

          .ivu-tag-checked {
            background: #00b6ff !important;
          }
        }
      }

      &.company {
        .ivu-radio-wrapper {
          display: block;
        }

        /deep/ .ivu-tooltip {
          width: 100%;

          .ivu-tooltip-rel {
            width: 100%;

            .item {
              @include flex;
              width: 100%;

              span {
                @include ellipsis;
                width: 100%;
              }

              i {
                margin-left: 5px;
                flex-shrink: 0;
              }
            }
          }
        }
      }
    }

    .option-line {
      @include flex(flex-start);

      .item-title {
        margin-right: 10px;
        cursor: pointer;

        &.active {
          color: #00b6ff;
        }
      }
    }
  }

  .btn-box {
    @include flex;
    margin: 18px 0 30px;
    color: #333;
    font-size: 14px;

    .btn {
      @include flex;
      width: 120px;
      height: 40px;
      margin-right: 14px;
      border: 1px solid rgba(228, 228, 228, 1);
      border-radius: 5px;
      cursor: pointer;

      &.check {
        width: 144px;
        color: #fff;
        background-color: #00b6ff;
      }

      &.last-child {
        margin-right: 0;
      }
    }
  }

  .choose-box {
    @include flex(flex-start);
    width: 100%;
    margin-bottom: 20px;

    .title {
      flex-shrink: 0;
      color: #333;
    }

    // .des {
    // }
  }

  .table-box {
    width: 100%;

    .top {
      @include flex(flex-end);
      position: relative;
      min-height: 40px;
      // padding-right: 20px;
      background-color: #f0f0f0;
      width: 100%;

      .icon-box {
        width: 90px;
        height: 40px;
        // position: absolute;
        // top: 0;
        // right: 0;
        font-size: 0;
        display: flex;

        .icon {
          @include flex;
          position: relative;
          width: 45px;
          height: 100%;
          cursor: pointer;

          &:nth-child(1) {
            &::before {
              @include bgSrc("table.png");
            }

            &.active::before {
              @include bgSrc("table-a.png");
            }
          }

          &:nth-child(2) {
            &::before {
              @include bgSrc("chart.png");
            }

            &.active::before {
              @include bgSrc("chart-a.png");
            }
          }

          &.active {
            background-color: #a2abbb;

            &::after {
              content: "";
              width: 0;
              height: 0;
              border-top: 7px solid transparent;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 7px solid #fff;
              position: absolute;
              left: 50%;
              bottom: 0;
              transform: translateX(-50%);
            }
          }

          &::before {
            width: 25px;
            height: 24px;
            content: "";
          }
        }
      }

      .action-box {
        @include flex;
        margin-left: auto;

        .action-box-radio {
          @include flex;
          margin-left: auto;

          .ivu-radio-wrapper {
            @include flex;
          }
        }

        .select-box {
          position: relative;
          margin-right: 20px;

          .select-index {
            /deep/ .ivu-tag {
              display: none;
            }
          }

          .place {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translate(0, -50%);
            pointer-events: none;
          }
        }
      }

      .select-box {
        position: relative;
        display: inline-block;

        /deep/ .ivu-tag {
          display: none;
        }

        .place {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translate(0, -50%);
          pointer-events: none;
        }
      }

      .table-opt {
        @include flex;
      }
    }

    .ivu-page {
      position: relative;
      margin: 15px 0;
      text-align: right;
    }

    /deep/ .btn-diary {
      color: #00b6ff;
      cursor: pointer;
    }
  }

  .canvas-box {
    width: 100%;
    height: 700px;

    canvas {
      width: 100%;
      height: 100%;
    }
  }

  .askInfo {
    width: 18px;
    height: 18px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    display: inline-block;
    color: #D9001B;
    border: 1px solid #808695;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
